import { instance } from '@/utils/http.js'

//打印试卷
export const exportWord = function (params) {
  return instance({
    url: '/api/v1/my/print_pdf',
    method: 'get',
    // responseType: "blob",
    params
  })
}

// 导出面批试卷（答题卡)
export const exportSheet = function (data) {
  return instance({
    url: '/api/v1/no_nstandard/pdf',
    method: 'post',
    data
  })
}